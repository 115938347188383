import React from "react";

import OurServices from "./OurServices";

const Services = () => {
  return (
    <div>
      <OurServices />
    </div>
  );
};

export default Services;
